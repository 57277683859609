.main {
  margin: 10px 20px;
}

.lease-form {
  padding: 24px;
}

.lease-form .ant-form-item {
  display: flex;
}

.lease-form .ant-form-item-control-wrapper {
  flex: 1;
}